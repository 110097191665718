import React from 'react';
import * as Styled from './homeBlueSectionStyle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

function HomeBlueSection({ yourPropaneCompany, orderPropane, showModal }) {
  const optionYourPropaneCompanyStyle = {
    renderMark: {
      [MARKS.CODE]: (node) => (
        <Styled.GreenSpanYourPropaneCompany>
          {node}
        </Styled.GreenSpanYourPropaneCompany>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParagraphYourPropaneCompany>
          {children}
        </Styled.ParagraphYourPropaneCompany>
      ),
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.Header2YourPropaneCompany>
          {children}
        </Styled.Header2YourPropaneCompany>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.Header3YourPropaneCompany>
          {children}
        </Styled.Header3YourPropaneCompany>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a
        href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {children}
        </a>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  const optionOrderPropaneStyle = {
    renderMark: {
      [MARKS.CODE]: (node) => (
        <Styled.GreenSpanOrderPropane>{node}</Styled.GreenSpanOrderPropane>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParagraphOrderPropane>{children}</Styled.ParagraphOrderPropane>
      ),
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.Header2OrderPropane>{children}</Styled.Header2OrderPropane>
      ),
      [INLINES.HYPERLINK]: (node, children) =>
        node?.data?.uri?.includes('modal') ? (
          <Styled.Button onClick={() => showModal((prev) => !prev)}>
            {children}
          </Styled.Button>
        ) : (
          <Styled.Button
            href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
            target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
            rel="noreferrer"
          >
            {children}
          </Styled.Button>
        ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.HomeSplit>
      <Styled.YourPropaneCompany
        $backgroundImageURL={yourPropaneCompany?.image?.file?.url}
      >
        {yourPropaneCompany?.richText?.raw &&
          documentToReactComponents(
            JSON.parse(yourPropaneCompany?.richText?.raw),
            optionYourPropaneCompanyStyle
          )}
      </Styled.YourPropaneCompany>
      <Styled.OrderPropane>
        <Styled.OrderOnlineText>
          {orderPropane?.richText?.raw &&
            documentToReactComponents(
              JSON.parse(orderPropane?.richText?.raw),
              optionOrderPropaneStyle
            )}
        </Styled.OrderOnlineText>
        <Styled.OrderOnlineLaptop>
          <Styled.OrderOnlineLaptopImage
            src={orderPropane?.image?.file?.url}
            alt="Order Fuel Online Laptop"
            data-aos="fade-left"
            data-aos-delay="400"
            data-aos-once
          />
        </Styled.OrderOnlineLaptop>
      </Styled.OrderPropane>
    </Styled.HomeSplit>
  );
}

export default HomeBlueSection;
