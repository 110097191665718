import styled from 'styled-components';

export const HomeSplit = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 1199px) {
    display: block;
  }
`;

export const YourPropaneCompany = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 150px;
  text-align: center;
  background: #0e4c80 url(${(props) => props.$backgroundImageURL}) no-repeat
    center center;
  background-size: 450px auto;

  @media (max-width: 1830px) {
    flex-basis: 40%;
    padding: 100px 75px;
    background-size: 400px auto;
  }
  @media (max-width: 1300px) {
    background-size: 350px auto;
  }
  @media (max-width: 767px) {
    padding: 45px 35px 45px 35px;
    background-size: 250px auto;
  }
`;

export const OrderPropane = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 1;
  background: rgb(11, 40, 71);
  background: -moz-linear-gradient(
    top,
    rgba(14, 76, 128, 1) 0%,
    rgba(11, 40, 71, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(14, 76, 128, 1) 0%,
    rgba(11, 40, 71, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(14, 76, 128, 1) 0%,
    rgba(11, 40, 71, 1) 100%
  );
  overflow: hidden;

  @media (max-width: 1830px) {
    flex-basis: 60%;
  }
  @media (max-width: 1199px) {
    background: rgb(11, 40, 71);
    background: -moz-linear-gradient(
      top,
      rgba(11, 40, 71, 1) 0%,
      rgba(14, 76, 128, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(11, 40, 71, 1) 0%,
      rgba(14, 76, 128, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(11, 40, 71, 1) 0%,
      rgba(14, 76, 128, 1) 100%
    );
  }
`;

export const OrderOnlineText = styled.div`
  float: left;
  width: 45%;
  padding: 150px 0 150px 80px;
  text-align: center;

  @media (max-width: 1830px) {
    padding: 100px 0 100px 80px;
  }
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    padding: 45px 35px 0 35px;
  }
`;

export const OrderOnlineLaptop = styled.div`
  float: right;
  width: 55%;
  padding-top: 95px;
  overflow: hidden;

  @media (max-width: 1300px) {
    padding-top: 80px;
  }

  @media (max-width: 767px) {
    float: none;
    width: 100%;
    padding: 45px 35px;
  }
`;

export const OrderOnlineLaptopImage = styled.img`
  width: 800px;
  height: auto;
  @media (max-width: 1830px) {
    width: 700px;
  }
  @media (max-width: 1199px) {
    width: 600px;
  }
  @media (max-width: 767px) {
    display: block;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const ParagraphYourPropaneCompany = styled.p`
  width: 550px;
  margin: 0 auto 60px auto;
  font-size: 18px;
  line-height: 26px;
  color: #fff;

  @media (max-width: 1830px) {
    width: 100%;
  }
  @media (max-width: 1199px) {
    width: 550px;
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Header2YourPropaneCompany = styled.h2`
  font-family: 'Adelle-Bold', arial, sans-serif;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const Header3YourPropaneCompany = styled.h3`
  font-family: 'Adelle', arial, sans-serif;
  font-style: italic;
  font-size: 40px;
  line-height: 40px;
  color: #55aa4c;

  @media (max-width: 1830px) {
    font-size: 30px;
    line-height: 30px;
  }
  @media (max-width: 1199px) {
    margin-bottom: 0;
  }
`;

export const GreenSpanYourPropaneCompany = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 80px;
  line-height: 80px;
  color: #55aa4c;

  @media (max-width: 1830px) {
    font-size: 60px;
    line-height: 60px;
  }
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const ParagraphOrderPropane = styled.p`
  width: 300px;
  margin: 0 auto 60px auto;
  font-size: 18px;
  line-height: 26px;
  color: #fff;

  @media (max-width: 1830px) {
    width: 100%;
  }
  @media (max-width: 1199px) {
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Header2OrderPropane = styled.h2`
  margin-bottom: 40px;
  font-family: 'Adelle-Bold', arial, sans-serif;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 35px;
  }
`;

export const GreenSpanOrderPropane = styled.span`
  display: block;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 45px;
  line-height: 45px;
  color: #55aa4c;

  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Button = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 17px 35px 13px 35px;
  margin: 0 5px 5px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #55aa4c;
  border: 2px solid #55aa4c;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  :hover {
    top: -5px;
    color: #55aa4c;
    border-color: #55aa4c;
    box-shadow: 0 5px 0 0 #55aa4c;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 767px) {
    padding: 10px 20px 6px 20px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
    padding-top: 8px;
    padding-bottom: 6px;
  }
`;
